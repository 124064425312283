/* eslint no-console:0 no-alert:0 */
import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Mailchimp extends React.Component {
    state = {
        NAME: null,
        EMAIL: null,
        PHONE: null,
        ADDRESS: null,
        YEAR: null,
        MAKE: null,
        MODEL: null,
        PREVDETAIL: null,
        EXTCOLOR: null,
        INTCOLOR: null,
    }

    _handleChange = e => {
        // console.log({
        //     [`${e.target.name}`]: e.target.value,
        // })
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault()

        // console.log('submit', this.state)

        addToMailchimp(this.state.EMAIL, this.state)
            .then(({ msg, result }) => {
                // console.log('msg', `${result}: ${msg}`)

                if (result !== 'success') {
                    throw msg
                }
                alert(msg)
            })
            .catch(err => {
                // console.log('err', err)
                alert(err)
            })
    }

    render() {
        return (
            <form onSubmit={this._handleSubmit}>
                <div className="form-field name">
                    {/* Your Name */}
                    <label htmlFor="mce-NAME">Your Name <span className="asterisk">*</span></label>
                    <input type="text" name="NAME" className="required" id="mce-NAME" onChange={this._handleChange} />            
                </div>
                <div className="form-field email">
                    {/* Your Email */}
                    <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" onChange={this._handleChange} />           
                </div>
                <div className="form-field phone">
                    {/* Your Phone Number */}
                    <label htmlFor="mce-PHONE">Phone Number <span className="asterisk">*</span></label>
                    <input type="text" name="PHONE" className="" id="mce-PHONE" onChange={this._handleChange} />
                </div>
                <div className="form-field address">
                    {/* Your Address */}
                    <label htmlFor="mce-ADDRESS">Address <span className="asterisk">*</span></label>
                    <input type="text" name="ADDRESS" className="required" id="mce-ADDRESS" onChange={this._handleChange} />   
                </div>
                <div className="form-field year">
                    {/* Year of Car */}
                    <label htmlFor="mce-YEAR">Vehicle Year <span className="asterisk">*</span></label>
                    <input type="number" name="YEAR" className="required" id="mce-YEAR" onChange={this._handleChange} />   
                </div>
                <div className="form-field make">
                    {/* Make of Car */}
                    <label htmlFor="mce-MAKE">Vehicle Make <span className="asterisk">*</span></label>
                    <input type="text" name="MAKE" className="required" id="mce-MAKE" onChange={this._handleChange} />
                </div>
                <div className="form-field model">
                    {/* Model of Car */}
                    <label htmlFor="mce-MODEL">Vehicle Model <span className="asterisk">*</span></label>
                    <input type="text" name="MODEL" className="required" id="mce-MODEL" onChange={this._handleChange} />
                </div>
                <div className="form-field prevdetail">
                    {/* Previous Detail */}
                    <label htmlFor="mce-PREVDETAIL">Have you detailed your vehicle before? If so, how: <span className="asterisk">*</span></label>
                    <input type="text" name="PREVDETAIL" className="required" id="mce-PREVDETAIL" onChange={this._handleChange} />        
                </div>
                <div className="form-field exterior-color">
                    {/* Exterior Color */}
                    <label htmlFor="mce-EXTCOLOR">Exterior Color <span className="asterisk">*</span></label>
                    <input type="text" name="EXTCOLOR" className="required" id="mce-EXTCOLOR" onChange={this._handleChange} />                   
                </div>
                <div className="form-field interior-color">
                    {/* Interior Color */}
                    <label htmlFor="mce-INTCOLOR">Interior Color <span className="asterisk">*</span></label>
                    <input type="text" name="INTCOLOR" className="required" id="mce-INTCOLOR" onChange={this._handleChange} />
                </div>
                <input type="submit" className="full-button" />
            </form>
        )
    }
}