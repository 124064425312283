import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Testimonials from "../components/testimonials"
import InstaContact from "../components/instafeed/contact"
import Mailchimp from "../components/mailchimp"


import { Container, Row } from 'react-bootstrap'

export default function Contact() {
    return (
        <div>
            <Layout>
                <SEO title="Home" />
                <div className="masthead white contact">
                    <Container>
                        <Row><h1>Contact</h1></Row>
                    </Container>
                </div>
                <div className="contact-wrapper">
                <div className="contact-gallery text-center flex align-center">
                    <InstaContact />
                </div>
                <div className="contact-form mb-5">
                    <h4>Professional Detailing, Headlight Restoration, Ceramic Coatings, and Polishing. Ready to love your vehicle again?</h4>
                    <Mailchimp />
                </div>
                </div>
                <div className="contact-testimonials">
                <Container>
                    <Row>
                        <Testimonials />
                    </Row>
                </Container>
                </div>
            </Layout>    
        </div>
    )
}
